<template>
  <section>
    <div class="drop-container">
      <a class="drop-option" @click="scrollToSection('#home-why')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/why-invrs-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('why.title')}}</h3>
          <p class="drop-text">{{$t('why.headerShort')}}</p>
        </div>
      </a>
      <a class="drop-option" @click="scrollToSection('#home-education')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/invrs-education-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('edu.title')}}</h3>
          <p class="drop-text">{{$t('edu.headerShort')}}</p>
        </div>
      </a>
      <a class="drop-option" @click="scrollToSection('#home-data')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/data-and-analysis-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('data.title')}}</h3>
          <p class="drop-text">{{$t('data.headerShort')}}</p>
        </div>
      </a>
      <a class="drop-option" @click="scrollToSection('#home-contest')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/invrs-contests-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('contests.title')}}</h3>
          <p class="drop-text">{{$t('contests.headerShort')}}</p>
        </div>
      </a>
      <a class="drop-option" @click="scrollToSection('#home-testimonials')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/testimonials-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('tests.title')}}</h3>
          <p class="drop-text">{{$t('tests.headerShort')}}</p>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToSection(id) {
      this.$emit("home-option-click", id);
    },
  },
};
</script>

<style scoped>
section {
  width: 100%;
}

.drop-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2.4rem;
  align-items: top;
  padding: 2.4rem 3.2rem 1.2rem;
  background-color: #fff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
}

.drop-option {
  border: #f2f4fa 2px solid;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.1s ease-in;
}

.drop-option:hover {
  transform: translateY(-0.6rem);
  -webkit-filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  cursor: pointer;
}

.icon-container {
  position: relative;
  background-color: #e0f6ed;
  height: 8rem;
}

.drop-img {
  position: absolute;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drop-content {
  padding: 1.6rem;
  background-color: #fff;
  height: 100%;
}

.drop-heading {
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
}

.drop-text {
  font-size: 1.6rem;
  line-height: 1.3;
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  .drop-container {
    grid-gap: 1.8rem;
  }
}
</style>
