<template>
  <section>
    <div class="drop-container">
      <a class="drop-option" @click="yourJourneyOptionClick('#home-why')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/why-invrs-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('why.title')}}</h3>
          <p class="drop-text">{{$t('why.headerShort')}}</p>
        </div>
      </a>
      <a class="drop-option" @click="yourJourneyOptionClick('#home-education')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/invrs-education-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('edu.title')}}</h3>
          <p class="drop-text">{{$t('edu.headerShort')}}</p>
        </div>
      </a>
      <a class="drop-option" @click="yourJourneyOptionClick('#home-data')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/data-and-analysis-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('data.title')}}</h3>
          <p class="drop-text">{{$t('data.headerShort')}}</p>
        </div>
      </a>
      <a class="drop-option" @click="yourJourneyOptionClick('#home-contest')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/invrs-contests-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('contests.title')}}</h3>
          <p class="drop-text">{{$t('contests.headerShort')}}</p>
        </div>
      </a>
      <a class="drop-option" @click="yourJourneyOptionClick('#home-testimonials')">
        <div class="icon-container">
          <img class="drop-img" src="/assets/testimonials-icon.png" />
        </div>
        <div class="drop-content">
          <h3 class="drop-heading">{{$t('tests.title')}}</h3>
          <p class="drop-text">{{$t('tests.headerShort')}}</p>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    yourJourneyOptionClick(id) {
      this.$emit("your-journey-option-click", id);
    },
  },
};
</script>

<style scoped>
.drop-container {
  display: flex;
  flex-direction: column;
  padding: 0 3.2rem;
}

.drop-option {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  border: 2px solid #f2f4fa;
  border-radius: 0.8rem;
  overflow: hidden;
  cursor: pointer;
}

.drop-content {
  display: flex;
  flex-direction: column;
  padding: 1.8rem;
  width: 70%;
}

.icon-container {
  position: relative;
  background-color: #e0f6ed;
  height: 10.4rem;
  width: 30%;
}

.drop-img {
  position: absolute;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drop-heading {
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
}

.drop-text {
  font-size: 1.6rem;
  line-height: 1.3;
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .drop-container {
    padding: 0 2.4rem;
  }
}
</style>
